import React, { useEffect, useState, useRef } from "react";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { makeStyles } from "@material-ui/core";
 
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import { Image } from "react-bootstrap";
 
import Button from "@material-ui/core/Button";
import { propTypes } from "react-bootstrap/esm/Image";
import chooseKeyboard from "../../components/KeyboardLayouts/layouts.js";

 
const elementSytle = {
 width: "90%",
};
const boxWidth = 500;
const boxMarginLeft = 10;
const boxMarginTop = 5;
const useStyles = makeStyles((theme) => ({
 root: {
   "& > *": {
     margin: theme.spacing(1),
   },
 },
 margin: {
   margin: theme.spacing(1),
 },
 withoutLabel: {
   marginTop: theme.spacing(3),
 },
 textField: {
   width: "25ch",
 },
 boxStyle: {
   minWidth: boxWidth,
   marginTop: boxMarginTop,
 },
}));
var audioVar;
 
function AddWordDialog({
 okCallback,
 updateCallback,
 deleteCallback,
 cancelCallback,
 updateFlag,
 row,
 visible,
 secondaryAppLang,
 language,
}) {
 console.log(language);
 const [layout, setLayout] = useState("default");
 const [input, setInput] = useState("");
 const [selectedField, setSelectedField] = useState("");

 const keyboard = useRef();
 let twiKeyboard = {
  default: [
    "ɛ ɔ",
    "{shift} {shift}",
  ],
  shift: [
    "Ɛ Ɔ",
    "{shift} {shift}",
  ],
};
 const onVirtualKeyboardChange = input => {
   if(selectedField === "word"){
     setWord(word+input);
     keyboard.current.setInput('');
   }
   if(selectedField === "description"){
     setDescription(description+input);
     keyboard.current.setInput('');
   }
   if(selectedField === "context"){
     setContext(context+input);
     keyboard.current.setInput('');
   }
   if(selectedField === "fr"){
     setFr(fr+input);
     keyboard.current.setInput('');
   }
   if(selectedField === "es"){
     setEs(es+input);
     keyboard.current.setInput('');
   }
   if(selectedField === "pt"){
     setPt(pt+input);
     keyboard.current.setInput('');
   }
 };
 const handleShift = () => {
   const newLayoutName = layout === "default" ? "shift" : "default";
   setLayout(newLayoutName);
 };
 const onKeyPress = (button) => {
   console.log("Button pressed", button);
 
   /**
    * If you want to handle the shift and caps lock buttons
    */
   if (button === "{shift}" || button === "{lock}") handleShift();
 };
 const [virtualKeyboardInput, setVirtualKeyboardInput] = useState("");
 //console.log(row);
 const width = 600;
 const [word, setWord] = useState(() => {
   if (row.word) {
     return row.word;
   } else {
     return "";
   }
 });
 const [description, setDescription] = useState(() => {
   if (row.word && row.description) {
     return row.description;
   } else {
     return "";
   }
 });
 const [fr, setFr] = useState(() => {
   if (row.word && row.fr) {
     return row.fr;
   } else {
     return "";
   }
 });
 const [pt, setPt] = useState(() => {
   if (row.word && row.pt) {
     return row.pt;
   } else {
     return "";
   }
 });
 const [es, setEs] = useState(() => {
   if (row.word && row.es) {
     return row.es;
   } else {
     return "";
   }
 });
 const [context, setContext] = useState(() => {
   if (row.word && row.context) {
     return row.context;
   } else {
     return "";
   }
 });
 const [audio, setAudio] = useState(() => {
   if (row.word && row.audio) {
     return row.audio;
   } else {
     return "";
   }
 });
 const [image, setImage] = useState(() => {
   if (row.word && row.image) {
     return row.image;
   } else {
     return "";
   }
 });
 const [imageFile, setImageFile] = useState(null);
 const [audioFile, setAudioFile] = useState(null);
 const [audioFileName, setAudioFileName] = useState("Play");
 
 const [audioObj, setAudioObj] = useState(() => {
   if (row.word && row.audio) {
     return row.audio;
   } else {
     return "";
   }
 });
 useEffect(() => {}, []);
 
 const classes = useStyles();
 const theme = useTheme();
function chooseCorrectLangToUpdate(value) {
 console.log(secondaryAppLang);
 if (secondaryAppLang === "fr") {
   setFr(value);
 } else if (secondaryAppLang === "pt") {
   setPt(value);
 } else if (secondaryAppLang === "es") {
   setEs(value);
 }
}
function getCorrectLanguage(word){
 if(secondaryAppLang === 'French'){
   return row.fr;
 }
 if(secondaryAppLang === 'Spanish'){
   return row.es;
 }
 if(secondaryAppLang === 'Portugese'){
   return row.pt;
 }
}
 const handleAdd = () => {
   if (
     description == "" ||
     word == "" ||
     description == undefined ||
     word == undefined
   ) {
     alert("Please enter all fields.");
   } else {
     const data = {
       word,
       description,
       fr,
       context,
       audio,
       image,
       imageFile,
       audioFile,
     };
     okCallback(data);
   }
  
 };
 
 const handleCancel = () => {
   cancelCallback();
 };
 const handleUpdate = () => {
   if (
     description == "" ||
     word == "" ||
     word == undefined ||
     description == undefined
   ) {
     alert("Please enter all fields.");
   } else {
     const data = {
       word,
       description,
       fr,
       context,
       index: row.index,
       audio,
       image,
       imageFile,
       audioFile,
     };
     updateCallback(data);
   }
 };
 const handleDelete = () => {
   const data = {
     word,
     description,
     fr,
     context,
     index: row.index,
     audio,
     image,
   };
   deleteCallback(data);
 };
 const onImageFileChange = (event) => {
   let file = event.target.files[0];
   setImage(URL.createObjectURL(file));
   setImageFile(file);
 };
 useEffect(() => {
   if (audioVar) {
     audioVar.pause();
     audioVar = null;
     setAudioFileName("Play");
   }
   if (audioObj) {
     audioVar = new Audio(audioObj);
     audioVar.onended = () => {
       setAudioFileName("Play");
     };
   }
 }, [audioObj]);
 
 const handleAudioClick = () => {
   if (audioFileName === "Play") {
     audioVar.play();
     setAudioFileName("Pause");
   } else {
     audioVar.pause();
     setAudioFileName("Play");
   }
 };
 
 const addAudioFile = (e) => {
   if (e.target.files[0]) {
     setAudioObj(URL.createObjectURL(e.target.files[0]));
     setAudioFile(e.target.files[0]);
   }
 };
 
 const style = {
   display: "flex",
   flexDirection: "column",
   bgcolor: "#727981",
   justifyContent: "flex-start",
   alignItems: "center",
   width: "100%",
 };
 
 return (
   // <div className={classes.root}>
   <Dialog style={style} open={visible} onClose={cancelCallback}>
     <div
       style={{
         display: "flex",
         flexDirection: "column",
         justifyContent: "flex-start",
         alignItems: "center",
         marginTop: 5,
         height: "90vh",
       }}
     >
       <div
         style={{
           display: "flex",
           flexDirection: "column",
           justifyContent: "center",
           alignItems: "center",
           width: width,
         }}
       >
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <TextField
             style={{ width: "100%" }}
             id="word"
             label="Word"
             value={word}
             onClick={() => {setSelectedField("word")}}
             //   disabled={updateFlag}
             InputProps={{
               disableUnderline: false,
               margin: "dense",
             }}
             onChange={(e) => {
               setWord(e.target.value);
             }}
             InputLabelProps={{ shrink: true }}
           ></TextField>
         </Box>
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <TextField
             style={{ width: "100%" }}
             id="description"
             label="ENGLISH"
             value={description}
             InputProps={{
               disableUnderline: false,
               margin: "dense",
             }}
             onClick={() => {setSelectedField("description")}}
             onChange={(e) => {
               setDescription(e.target.value);
             }}
             InputLabelProps={{ shrink: true }}
           ></TextField>
         </Box>
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <TextField
             style={{ width: "100%" }}
             id="secondaryAppLang"
             label={secondaryAppLang}
             onClick={() => {setSelectedField(getCorrectLanguage())}}
             value={getCorrectLanguage()}
             InputProps={{
               disableUnderline: false,
               margin: "dense",
             }}
             onChange={(e) => {
               chooseCorrectLangToUpdate(e.target.value);
             }}
             InputLabelProps={{ shrink: true }}
           ></TextField>
         </Box>
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <TextField
             style={{ width: "100%" }}
             id="context"
             label="CONTEXT"
             value={context}
             InputProps={{
               disableUnderline: false,
               margin: "dense",
             }}
             onClick={() => {setSelectedField("context")}}
             onChange={(e) => {
               setContext(e.target.value);
             }}
             InputLabelProps={{ shrink: true }}
           ></TextField>
         </Box>
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <button onClick={handleAudioClick}>{audioFileName}</button>
          Change the Audio File <input type="file" accept=".mp3,audio/*" onChange={addAudioFile} />
           {/* {audioObj ? (
             <button onClick={()=>{
               setAudioObj(null)
               setAudioFile(null)
             }}>Remove Image</button>
           ) : (
             <></>
           )} */}
         </Box>
         <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
           <div>
             <Image src={image} style={{ width: "200px", height: "200px" }} />
           </div>
 
           Change the Image <input
             type="file"
             name="image"
             accept="image/*"
             // disabled={this.state.disabledArr[index]}
             onChange={(e) => onImageFileChange(e)}
           />
           {image ? (
             <button onClick={()=>{
               setImage(null)
               setImageFile(null)
             }}>Remove Image</button>
           ) : (
             <></>
           )}
         </Box>
       </div>
 
       {updateFlag ? (
         <div
           style={{
             display: "flex",
             flexDirection: "row",
             justifyContent: "space-evenly",
             alignItems: "center",
             width: "90%",
           }}
         >
           <Button
             style={{
               backgroundColor: "#379683",
               color: "white",
               marginTop: 5,
               marginBottom: 5,
               // width: 40,
             }}
             onClick={handleUpdate}
           >
             UPDATE
           </Button>
           <Button
             style={{
               backgroundColor: "red",
               color: "white",
               marginTop: 5,
               marginBottom: 5,
               width: 40,
             }}
             onClick={handleDelete}
           >
             DELETE
           </Button>
           <Button
             style={{
               backgroundColor: "#379683",
               color: "white",
               marginTop: 5,
               marginBottom: 5,
               width: 40,
             }}
             onClick={handleCancel}
           >
             CANCEL
           </Button>
         </div>
       ) : (
         <div
           style={{
             display: "flex",
             flexDirection: "row",
             justifyContent: "space-evenly",
             alignItems: "center",
             width: "90%",
           }}
         >
           <Button
             style={{
               backgroundColor: "#379683",
               color: "white",
               marginTop: 5,
               marginBottom: 5,
               width: 40,
             }}
             onClick={handleAdd}
           >
             ADD
           </Button>
           <Button
             style={{
               backgroundColor: "#379683",
               color: "white",
               marginTop: 5,
               marginBottom: 5,
               width: 40,
             }}
             onClick={handleCancel}
           >
             CANCEL
           </Button>
         </div>
       )}
       <Keyboard
       layout={chooseKeyboard(language.id)}
          keyboardRef={r => (keyboard.current = r)}
       layoutName={layout}
       onChange={onVirtualKeyboardChange}
       onKeyPress={onKeyPress}
     />
     </div>
   </Dialog>
 );
}
 
export default AddWordDialog