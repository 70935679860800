import React, { useEffect, useState } from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
function Loader({ loading }) {
    //console.log(loading)
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Bounce color="#727981" size={50} speed={1} animating={true} />{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Loader;