function chooseKeyboard(lang){
    switch(lang){
        case 'Twi':
        return twiKeyboard;
        case 'Yoruba':
        return yorubaKeyboard;
        case 'Amharic':
        return amharicKeyboard;
        case 'Arabic':
        return arabicKeyboard;
        case 'Igbo':
        return igboKeyboard;
        case 'Hausa':
        return hausaKeyboard;
        case 'Mooré':
        return mooréKeyboard;
        case 'Kassem':
        return kassemKeyboard;

        default:
            return generalKeyboard;

    }
}

let twiKeyboard = {
    default: [
      "ɛ ɔ",
      "{shift} {shift}",
    ],
    shift: [
      "Ɛ Ɔ",
      "{shift} {shift}",
    ],
  };
  let yorubaKeyboard = {
    default: [
        "ˊ 1 2 3 4 5 6 7 8 9 0 ɗ ƙ {bksp}",
        "{tab} q w e r t y u i o p ụ ị",
        "{lock} a s d f g h j k l ọ ẹ ǝ {enter}",
        "{shift} z y x c v b n m , . ṣ {shift}",
        ".com @ {space}",
      ],
      shift: [
        'ˆ ! " / _ ₦ % = - | ( ) Ɗ Ƙ {bksp}',
        "{tab} Q W E R T Y U I O P Ụ Ị",
        "{lock} A S D F G H J K L Ọ Ẹ Ǝ {enter}",
        "{shift} Z Ɓ C V B N M ; : Ṣ {shift}",
        ".com @ {space}",
      ],
  };
  let igboKeyboard = {
    default: [
        "ˊ 1 2 3 4 5 6 7 8 9 0 ɗ ƙ {bksp}",
        "{tab} q w e r t y u i o p ụ ị",
        "{lock} a s d f g h j k l ọ ẹ ǝ {enter}",
        "{shift} z y x c v b n m , . ṣ {shift}",
        ".com @ {space}",
      ],
      shift: [
        'ˆ ! " / _ ₦ % = - | ( ) Ɗ Ƙ {bksp}',
        "{tab} Q W E R T Y U I O P Ụ Ị",
        "{lock} A S D F G H J K L Ọ Ẹ Ǝ {enter}",
        "{shift} Z Ɓ C V B N M ; : Ṣ {shift}",
        ".com @ {space}",
      ],
  };
  let hausaKeyboard = {
    default: [
        "ˊ 1 2 3 4 5 6 7 8 9 0 ɗ ƙ {bksp}",
        "{tab} q w e r t y u i o p ụ ị",
        "{lock} a s d f g h j k l ọ ẹ ǝ {enter}",
        "{shift} z y x c v b n m , . ṣ {shift}",
        ".com @ {space}",
      ],
      shift: [
        'ˆ ! " / _ ₦ % = - | ( ) Ɗ Ƙ {bksp}',
        "{tab} Q W E R T Y U I O P Ụ Ị",
        "{lock} A S D F G H J K L Ọ Ẹ Ǝ {enter}",
        "{shift} Z Ɓ C V B N M ; : Ṣ {shift}",
        ".com @ {space}",
      ],
  };
  let amharicKeyboard = {
    default: [
        "ሀ 1 2 3 4 5 6 7 8 9 0 - {bksp}",
        "{tab} ለ ሐ ሠ ረ ቀ ሰ ሶ ነ ወ ጀ ፈ አ ደ ተ አቀ",
        "{lock} መ ሚ ሜ ሸ ተ አሀ ወጀ ፈአ ደተ አቀ ወደ",
    ],
  }
  let arabicKeyboard = {
    default: [
        "\u0630 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "{tab} \u0636 \u0635 \u062B \u0642 \u0641 \u063A \u0639 \u0647 \u062E \u062D \u062C \u062F \\",
        "{lock} \u0634 \u0633 \u064A \u0628 \u0644 \u0627 \u062A \u0646 \u0645 \u0643 \u0637 {enter}",
        "{shift} \u0626 \u0621 \u0624 \u0631 \u0644\u0627 \u0649 \u0629 \u0648 \u0632 \u0638 {shift}",
        ".com @ {space}",
      ],
      shift: [
        "\u0651 ! @ # $ % ^ & * ) ( _ + {bksp}",
        "{tab} \u064E \u064B \u064F \u064C \u0644\u0625 \u0625 \u2018 \u00F7 \u00D7 \u061B < > |",
        '{lock} \u0650 \u064D ] [ \u0644\u0623 \u0623 \u0640 \u060C / : " {enter}',
        "{shift} ~ \u0652 } { \u0644\u0622 \u0622 \u2019 , . \u061F {shift}",
        ".com @ {space}",
      ],
    }
let mooréKeyboard = {
    default: [
        "1 2 3 4 5 6 7 8 9 0 - {bksp}",
        "{tab} q w e r t y u i o p ụ ị",
        "{lock} a s d f g h j k l ọ ẹ ǝ {enter}",
        "{shift} z y x c v b n m , . ṣ {shift}",
        ".com @ {space}",
        ],
        shift: [
            "! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P Ụ Ị",
            "{lock} A S D F G H J K L Ọ Ẹ Ǝ {enter}",
            "{shift} Z Ɓ C V B N M ; : Ṣ {shift}",
            ".com @ {space}",
        ],
    }
    let kassemKeyboard = {
        default: [
            "1 2 3 4 5 6 7 8 9 0 - {bksp}",
            "{tab} q w e r t y u i o p ụ ị",
            "{lock} a s d f g h j k l ọ ẹ ǝ {enter}",
            "{shift} z y x c v b n m , . ṣ {shift}",
            ".com @ {space}",
            ],
            shift: [
                "! @ # $ % ^ & * ( ) _ + {bksp}",
                "{tab} Q W E R T Y U I O P Ụ Ị",
                "{lock} A S D F G H J K L Ọ Ẹ Ǝ {enter}",
                "{shift} Z Ɓ C V B N M ; : Ṣ {shift}",
                ".com @ {space}",
            ],
        }
    let generalKeyboard = {
    default: [
        "q w e r t y u i o p",
        "a s d f g h j k l",
        "{shift} {shift}",
        "z x c v b n m",
    ],
    shift: [
        "Q W E R T Y U I O P",
        "A S D F G H J K L",
        "{shift} {shift}",
        "Z X C V B N M",
    ],
    };
    export default chooseKeyboard;