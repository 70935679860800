import React, { useEffect, useContext } from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import LanguageDashboard from "./LanguageDashboard/LanguageDashboard";
import CourseDashboard from "./CourseDashboard/CourseDashboard";
import LoginDashboard from "./LoginDashboard/LoginDashboard";
import { UserContext } from "../providers/userDataProvider";



function Application(props) {
  const userContext = useContext(UserContext);


return userContext.user ? (
  <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/Dashboard/" exact component={Dashboard} />
          <Route
            path="/:language/Dashboard/"
            exact
            component={LanguageDashboard}
          />
          <Route
            path="/course/:courseid/Dashboard/"
            exact
            component={CourseDashboard}
          />
          {<Redirect path="*" to="/" />}
        </Switch>
    </BrowserRouter>
  ) : (
   <BrowserRouter>
        <Switch>
          <Route path="/" exact component={LoginDashboard} />
          <Route path="/Login/" exact component={LoginDashboard} />
         
          {<Redirect path="*" to="/" />}
        </Switch>
    </BrowserRouter>
  );

}

export default Application;
