import React, { Component, useState, useEffect, useContext } from "react";
// import { Route, Switch, withRouter, Redirect, Link } from "react-router-dom";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  Link,
  useLocation,
} from "react-router-dom";
// import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/firestore";
import axios from "axios";
import { Image } from "react-bootstrap";
import {
  getCourseDetail,
  updateCourseWords,
  uploadFileToStorage,
  deleteFileFromFirebase,
} from "../../providers/firebaseHandler";
import cloneDeep from "lodash/cloneDeep";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddWordDialog from "../../components/Dialog/AddWord";
import swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../components/Dialog/Loader";
import { FirebaseContext } from "../../providers/firebaseContextProvider";
function CourseDashboard(props) {
  const verticalMargin = "20px";
  const courseid = props.match.params.courseid;
  const [course, setCourse] = useState(null);
  const [name, setName] = useState(null);
  const [teaching, setTeaching] = useState({});
  const [words, setWords] = useState([]);
  const [origWords, setOrigWords] = useState([]);
  const [disabledArr, setDisabledArr] = useState([]);
  const [showEntryForm, setShowEntryForm] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const firebaseContext = useContext(FirebaseContext);

  const location = useLocation();
  // //console.log(location.pathname); // result: '/secondpage'
  // //console.log(location.search); // result: '?query=abc'
  ////console.log(location.state); // result: 'some_value'

  useEffect(() => {
    loadWords();
  }, []);

  const playAudio = (audio) => {
    const audio1 = new Audio(audio);
    var readyState = audio1.networkState;
    try {
      audio1.play();
    } catch (e) {
      alert("Error playing file!");
    }
  };

  const addWord = async (data) => {
    let word = {
      word: data.word,
      description: data.description,
      fr: data.fr,
      context: data.context,
      audio: data.audio,
      image: data.image,
    };
    setShowEntryForm(false);

    swal
      .fire({
        title: "Are you sure you want to add " + data.word + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add it!",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);

          if (data.imageFile !== null) {
            const name = data.imageFile.name;
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1);
            const downloadURL = await uploadFileToStorage(
              data.imageFile,
              courseid + "/" + word.word + "/",
              "image." + ext
            );
            word.image = downloadURL;
          }
          if (data.audioFile !== null) {
            const name = data.audioFile.name;
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1);
            const downloadURL = await uploadFileToStorage(
              data.audioFile,
              courseid + "/" + word.word + "/",
              "audio." + ext
            );
            word.audio = downloadURL;
          }

          let res = await updateCourseWords(courseid, words.concat(word));
          loadWords();
          if (res === "done") {
            toast.success("Added successfully!");
          } else {
            toast.success("Something went wrong. try again!");
          }
        }
      });
  };

  const updateWord = async (data) => {
    setShowEntryForm(false);
    const word = {
      word: data.word,
      description: data.description,
      fr: data.fr,
      context: data.context,
      audio: data.audio,
      image: data.image,
    };
    let reducedArr = [...words];
    reducedArr[data.index] = word;
    swal
      .fire({
        title: "Are you sure to update " + data.word + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          if (data.imageFile !== null) {
            const name = data.imageFile.name;
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1);
            // const del = await deleteFileFromFirebase(word.image);
            const downloadURL = await uploadFileToStorage(
              data.imageFile,
              courseid + "/" + word.word + "/",
              "image." + ext
            );
            word.image = downloadURL;
          }
          if (data.audioFile !== null) {
            const name = data.audioFile.name;
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1);
            const downloadURL = await uploadFileToStorage(
              data.audioFile,
              courseid + "/" + word.word + "/",
              "audio." + ext
            );
            word.audio = downloadURL;
          }
          let res = await updateCourseWords(courseid, reducedArr);
          loadWords();
          if (res === "done") {
            toast.success("Updated successfully!");
          } else {
            toast.success("Something went wrong. try again!");
          }
        }
      });
    //  setLoading(false)
  };
  const deleteWord = async (data) => {
    setShowEntryForm(false);
    swal
      .fire({
        title: "Are you sure to delete " + data.name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const reducedArr = [...words];
          reducedArr.splice(data.index, 1);

          let res = await updateCourseWords(courseid, reducedArr);
          loadWords();
          if (res === "done") {
            toast.success("Deleted successfully!");
          } else {
            toast.success("Something went wrong. try again!");
          }
        }
      });
  };

  const loadWords = async () => {
    ////console.log(firebaseContext.languages, courseid);
    // let selectedLanguage = ;
    // //console.log(selectedLanguage)
    setLoading(true);
    let res = await getCourseDetail(courseid);
    //  setLoading(false)
    if (res != "error") {
      setCourse(res);
      setName(res.name);
      let filteredarray = firebaseContext.languages.filter(
        (x) => x.id == res.teaching
      );
      setTeaching(filteredarray[0]);
      setWords(res.words);
      setOrigWords(cloneDeep(res.words));
      setDisabledArr(new Array(res.words?.length).fill(true));
    }
    setLoading(false);
  };

  return (
    <div style={{ backgroundColor: "white", width: "100vw", height: "100vh" }}>
      <Loader loading={loading} />
      <div
        style={{
          marginTop: verticalMargin,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography variant="h4" component="h2">
          {teaching.id} {name}
        </Typography>
        {teaching.edit || teaching.lead ? (
          <Button
            variant="contained"
            onClick={(e) => {
              setShowEntryForm(true);
              setUpdateFlag(false);
              setSelectedData({});
            }}
          >
            Add a Word
          </Button>
        ) : (
          <></>
        )}
      </div>
      <table>
        <thead>
          <tr>
            {teaching.edit || teaching.lead ? (
              <th style={{ border: "1px solid", width: "200px" }}> </th>
            ) : (
              <></>
            )}

            <th style={{ border: "1px solid", width: "200px" }}>
              {teaching.id}
            </th>
            <th style={{ border: "1px solid", width: "200px" }}> English </th>
            <th style={{ border: "1px solid", width: "200px" }}> French</th>
            <th style={{ border: "1px solid", width: "200px" }}> Context</th>
            <th style={{ border: "1px solid", width: "200px" }}> Audio </th>
            <th style={{ border: "1px solid", width: "200px" }}> Image</th>
          </tr>
        </thead>
        <tbody>
          {words.map((word, index) => (
            <tr key={index}>
              {teaching.edit || teaching.lead ? (
                <td>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      setShowEntryForm(true);
                      setUpdateFlag(true);
                      setSelectedData({ ...word, index });
                    }}
                  >
                    Edit/Delete
                  </Button>
                </td>
              ) : (
                <></>
              )}

              <td>
                <input
                  placeholder={word.word}
                  disabled={disabledArr[index]}
                  style={{ color: "black" }}
                />
              </td>
              <td>
                <input
                  placeholder={word.description}
                  disabled={disabledArr[index]}
                  style={{ color: "black" }}
                />
              </td>
              <td>
                <input
                  placeholder={word.fr}
                  disabled={disabledArr[index]}
                  style={{ color: "black" }}
                />
              </td>
              <td>
                <input
                  placeholder={word.context}
                  disabled={disabledArr[index]}
                  style={{ color: "black" }}
                />
              </td>
              <td>
                {word.audio ? (
                  <button onClick={() => playAudio(word.audio)}>
                    Play Audio
                  </button>
                ) : (
                  <>N/A</>
                )}
              </td>
              <td>
                {word.image ? (
                  <div>
                    <Image
                      src={word.image}
                      style={{ width: "200px", height: "200px" }}
                    />
                  </div>
                ) : (
                  <>N/A</>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <Button style={{ marginTop: "50px" }} onClick={this.addWord}>
          Add a Word
        </Button> */}
      {showEntryForm ? (
        <AddWordDialog
          okCallback={(data) => {
            addWord(data);
            // addLanguageData(data);
          }}
          cancelCallback={(e) => {
            setShowEntryForm(false);
          }}
          updateCallback={(data) => {
            updateWord(data);
          }}
          deleteCallback={(data) => {
            deleteWord(data);
          }}
          row={selectedData}
          visible={showEntryForm}
          updateFlag={updateFlag}
          language={teaching}
          // defaultType={type}
          // updateLevel3={updateLevel3}
        ></AddWordDialog>
      ) : (
        <></>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        closeOnClick={true}
        progress={undefined}
      />
    </div>
  );
}

export default CourseDashboard;
