import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDtMEXlQAmUFMyJaO9kTDarGPiM7rn2pQY",
  authDomain: "mandla-demo.firebaseapp.com",
  databaseURL: "https://mandla-demo-default-rtdb.firebaseio.com",
  projectId: "mandla-demo",
  storageBucket: "mandla-demo.appspot.com",
  messagingSenderId: "1069863514519",
  appId: "1:1069863514519:web:d49423a0e364ad9dca94d2",
  measurementId: "G-5PBLXC8YS6"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = app.firestore();
const  storage = firebase.storage();


const signInWithEmailAndPassword = async (email, password) => {
  try {
    // if (email.length == 11 || email == "webadmin") {
      await auth.signInWithEmailAndPassword(email, password);
    // } else {
    //   alert("Invalid Phone Number. Phone number must contains 11 digits.");
    // }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const getUserDocument = async (user, additionalData) => {
  if (!user) return;
  try {
    //console.log(user);
    const id = user.uid;
    const userDocument = await db.doc(`Admins/${id}`).get();
    //console.log("hello " + userDocument.data().role);
    
      return {
        id,
        ...userDocument.data(),
      };
   
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export {signInWithEmailAndPassword, getUserDocument,auth, db,storage
  
};