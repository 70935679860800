import React, { Component, useState, useEffect, useContext } from "react";
import { Route, Switch, withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/firestore";
import LanguageRow from "../../components/LanguageRow/LanguageRow";
import axios from "axios";
import {
  addLanguage,
  updateLanguage,
  deleteLanguage,
  getUsers,
  addUser,
  updateUser,
  deleteUser,
} from "../../providers/firebaseHandler";
import { Languages } from "../../providers/dataLanguages";
import { FirebaseContext } from "../../providers/firebaseContextProvider";
import AddLanguageDialog from "../../components/Dialog/AddLanguage";
import AddUserDialog from "../../components/Dialog/AddUser";
import { DataGrid } from "@material-ui/data-grid";
import { Popper, Box, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Dialog/Loader";
import { UserContext } from "../../providers/userDataProvider";
import { auth } from "../../firebase";
import { UserType } from "../../providers/config.js";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

function isOverflown(element) {
  return true;
  // return (
  //   element.scrollHeight > element.clientHeight ||
  //   element.scrollWidth > element.clientWidth
  // );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }
    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "36px",
        position: "relative",
        display: "flex",
        paddingRight: 10,
        width: "100%",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

// GridCellExpand.propTypes = {
//   value: PropTypes.string.isRequired,
//   width: PropTypes.number.isRequired,
// };

function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={params.value || ""}
      width={params.colDef.computedWidth}
    />
  );
}

function Dashboard() {
  const verticalMargin = "20px";
  let history = useHistory();
  const firebaseContext = useContext(FirebaseContext);
  const [showEntryForm, setShowEntryForm] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const userContext = useContext(UserContext);
  const [usersArray, setUsersArray] = React.useState([]);
  const [radioValue, setRadioValue] = useState("lang");
  const [showUserForm, setShowUserForm] = useState(false);
  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };
  const columns = [
    {
      field: "view",
      headerName: "View",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );
          history.push("/" + thisRow.name + "/Dashboard/");
          // return alert(JSON.stringify(thisRow, null, 4));
        };

        return (
          <Button variant="contained" onClick={onClick}>
            View Course
          </Button>
        );
      },
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "region", headerName: "Region", width: 150 },
    { field: "learnerCount", headerName: "Learner Count", width: 150 },
    {
      field: "about",
      headerName: "About",
      renderCell: renderCellExpand,
      flex: 1,
    },
  ];

  const getValue = (params) => {
    if (params.value) {
      let data = params.value.map(function (e) {
        return e.toUpperCase();
      });
      return data.join(", ");
    }
    return "";
  };

  const userColumns = [
    // {
    //   field: "edituser",
    //   headerName: "Edit",
    //   width: 150,
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation(); // don't select this row after clicking

    //       const api = params.api;
    //       const thisRow = {};

    //       api
    //         .getAllColumns()
    //         .filter((c) => c.field !== "__check__" && !!c)
    //         .forEach(
    //           (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
    //         );
    //       history.push("/" + thisRow.name + "/Dashboard/");
    //       // return alert(JSON.stringify(thisRow, null, 4));
    //     };

    //     return (
    //       <Button variant="contained" onClick={onClick}>
    //         Edit User
    //       </Button>
    //     );
    //   },
    // },
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      valueGetter: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "lead",
      headerName: "Lead",
      valueGetter: getValue,
      renderCell: renderCellExpand,
      width: 200,
    },
    {
      field: "edit",
      headerName: "Editor",
      valueGetter: getValue,
      renderCell: renderCellExpand,
      width: 200,
    },
    {
      field: "view",
      headerName: "Viewer",
      valueGetter: getValue,
      renderCell: renderCellExpand,
      flex: 1,
    },
  ];



  useEffect(async () => {
    // let role = "language-lead";
    // let leadLanguageArray = ["arabic"];
    // let editLanguageArray = [];
    // let viewLanguageArray = ["bambara"];
    firebaseContext.getLanguage(
      userContext.user.role,
      userContext.user.lead,
      userContext.user.edit,
      userContext.user.view
    );
    let usersData = await getUsers();
    setUsersArray(usersData);
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// USERS MANAGEMENT ///////////////////////////////
  const addUserData = async (data) => {
    //console.log(data);
    setShowUserForm(false);
    let role = "";
    if (data.lead.length > 0) {
      role = UserType.lead;
    } else if (data.edit.length > 0) {
      role = UserType.editor;
    } else {
      role = UserType.viewer;
    }
    let obj = {
      email: data.email,
      password: data.password,
      name: data.name,
      lead: data.lead,
      edit: data.edit,
      view: data.view,
      active: data.active,
      role,
    };
    swal
      .fire({
        title: "Are you sure to add new User?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add it!",
      })
      .then(async (result) => {
        setLoading(true);
        let res = await addUser(obj);
        if (res === "done") {
          let usersData = await getUsers();
          setUsersArray(usersData);
          setLoading(false);
          toast.success("Added successfully!");
        } else {
          toast.success("Something went wrong. try again!");
        }
      });
    setLoading(false);
  };
  const updateUserData = async (data) => {
    setShowUserForm(false);
    let role = "";
    if (data.lead.length > 0) {
      role = UserType.lead;
    } else if (data.edit.length > 0) {
      role = UserType.editor;
    } else {
      role = UserType.viewer;
    }
    let obj = {
      id: data.id,
      lead: data.lead,
      edit: data.edit,
      view: data.view,
      active: data.active,
      role,
    };
    swal
      .fire({
        title: "Are you sure to update " + data.name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      })
      .then(async (result) => {
        setLoading(true);
        let res = await updateUser(obj);
        setLoading(false);
        if (res === "done") {
          let usersData = await getUsers();
          setUsersArray(usersData);
          setLoading(false);
          toast.success("Update successfully!");
        } else {
          setLoading(false);
          toast.success("Something went wrong. try again!");
        }
      });
    setLoading(false);
  };
  const deleteUserData = async (data) => {
    setShowUserForm(false);

    let obj = {
      id: data.id,
    };
    swal
      .fire({
        title: "Are you sure to delete " + data.name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      })
      .then(async (result) => {
        setLoading(true);
        let res = await deleteUser(obj);
        //console.log(res)
        if (res === "done") {
          let usersData = await getUsers();
          setUsersArray(usersData);
                  setLoading(false);
          toast.success("Deleted successfully!");
        } else {
          setLoading(false);
          toast.success("Something went wrong. try again!");
        }
      });
    setLoading(false);
  };
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////// LANGUAGE MANAGEMENT ///////////////////////////////
  const addLanguageData = async (data) => {
    setShowEntryForm(false);
    let obj = {
      id: data.name,
      alphabet: [],
      learnerCount: 0,
      name: data.name,
      region: data.region,
      availWeb: data.availWeb,
      availMobile: data.availMobile,
      availTrans: data.availTrans,
      flag: "",
      transId: "",
      about: data.about,
    };
    swal
      .fire({
        title: "Are you sure to add new langugae?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add it!",
      })
      .then(async (result) => {
        setLoading(true);
        let res = await addLanguage(obj);
        setLoading(false);
        if (res === "done") {
          toast.success("Added successfully!");
        } else {
          toast.success("Something went wrong. try again!");
        }
      });
    setLoading(false);
  };
  const updateLanguageData = async (data) => {
    setShowEntryForm(false);
    let obj = {
      id: data.name,
      name: data.name,
      about: data.about,
      region: data.region,
      availWeb: data.availWeb,
      availMobile: data.availMobile,
      availTrans: data.availTrans,
    };
    swal
      .fire({
        title: "Are you sure to update " + data.name + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      })
      .then(async (result) => {
        setLoading(true);
        let res = await updateLanguage(obj);
        setLoading(false);
        if (res === "done") {
          toast.success("Update successfully!");
        } else {
          toast.success("Something went wrong. try again!");
        }
      });
    setLoading(false);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  function CellonClick(row, column, event) {
    if (row) {
      // setCode(row.row.code);
      // setDescription(row.row.description);
      // EnableButtonsView();
      // setTimeout(function () {
      //   if (descriptionRef.current) {
      //     descriptionRef.current.focus();
      //   }
      // }, 500);
    }
  }
  function updateUserRow(event, params) {
    if (userContext.user.role === UserType.superAdmin) {
      setSelectedData(params.row);
      setUpdateFlag(true);
      setShowUserForm(true);
    } else {
      alert("You are not authorized.");
    }
  }
  function updateRow(event, params) {
    if (userContext.user.role === UserType.superAdmin) {
      setSelectedData(params.row);
      setUpdateFlag(true);
      setShowEntryForm(true);
    } else {
      alert("You are not authorized.");
    }
  }

  return (
    <div style={{ justifyContent: "flex-end" }}>
      <Button
        variant="outlined"
        onClick={(e) => {
          userContext.logout();
        }}
      >
        Logout
      </Button>
      <div
        style={{
          marginTop: verticalMargin,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Loader loading={loading} />
        <Typography variant="h4" component="h2">
          DASHBOARD
        </Typography>
        <div></div>
        {userContext.user.role === UserType.superAdmin ? (
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="lang"
                control={<Radio />}
                label="Languages"
              />
              <FormControlLabel
                value="users"
                control={<Radio />}
                label="User Management"
              />
            </RadioGroup>
            {radioValue == "lang" && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setSelectedData({});
                  setUpdateFlag(false);
                  setShowEntryForm(true);
                }}
              >
                Add a Language
              </Button>
            )}
            {radioValue != "lang" && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setSelectedData({});
                  setUpdateFlag(false);
                  setShowUserForm(true);
                }}
              >
                Create a User
              </Button>
            )}
          </FormControl>
        ) : (
          <></>
        )}
        {radioValue == "lang" && (
          <div
            style={{
              marginTop: verticalMargin,
              justifyContent: "center",
              width: "95%",
              height: "80vh",
              backgroundColor: "#EDF5E1",
            }}
          >
            <DataGrid
              // onCellEnter={handleCellEnter}
              hideFooter
              // headerHeight={20}
              // rowHeight={25}
              style={{ width: "100%" }}
              // onCellClick={CellonClick}
              onCellDoubleClick={(params, event) => {
                updateRow(event, params);
              }}
              rows={firebaseContext.languages}
              columns={columns}
              pageSize={100}
              disableColumnMenu
            />
          </div>
        )}

        {showEntryForm ? (
          <AddLanguageDialog
            okCallback={(data) => {
              addLanguageData(data);
            }}
            cancelCallback={() => {
              setShowEntryForm(false);
            }}
            updateCallback={(data) => {
              updateLanguageData(data);
            }}

            row={selectedData}
            visible={showEntryForm}
            updateFlag={updateFlag}
            // defaultType={type}
            // updateLevel3={updateLevel3}
          ></AddLanguageDialog>
        ) : (
          <></>
        )}

        {showUserForm && (
          <AddUserDialog
            okCallback={(data) => {
              addUserData(data);
            }}
            cancelCallback={() => {
              setShowUserForm(false);
            }}
            updateCallback={(data) => {
              updateUserData(data);
            }}
            deleteCallback={(data) => {
              deleteUserData(data);
            }}
            multiSelectRowData={firebaseContext.languages}
            row={selectedData}
            visible={showUserForm}
            updateFlag={updateFlag}
          ></AddUserDialog>
        )}

        {radioValue != "lang" && (
          <div
            style={{
              marginTop: verticalMargin,
              justifyContent: "center",
              width: "95%",
              height: "80vh",
              backgroundColor: "#EDF5E1",
            }}
          >
            <DataGrid
              // onCellEnter={handleCellEnter}
              hideFooter
              // headerHeight={20}
              // rowHeight={25}
              style={{ width: "100%" }}
              // onCellClick={CellonClick}
              onCellDoubleClick={(params, event) => {
                updateUserRow(event, params);
              }}
              rows={usersArray}
              columns={userColumns}
              pageSize={100}
              disableColumnMenu
            />
          </div>
        )}
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
          progress={undefined}
        />
      </div>
    </div>
  );
}

export default Dashboard;
