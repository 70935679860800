import React, { useEffect, useState, useRef } from "react";

import { makeStyles } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Stack from '@material-ui/lab/Stack';

import Button from "@material-ui/core/Button";

const elementSytle = {
  width: "90%",
};
const boxWidth = 500;
const boxMarginLeft = 10;
const boxMarginTop = 5;
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  boxStyle: {
    minWidth: boxWidth,
    marginTop: boxMarginTop,
  },
}));

function AddUserByLangaugeDialog({
  okCallback,
  updateCallback,
  deleteCallback,
  cancelCallback,
  updateFlag,
  multiSelectRowData,
  row,
  visible,
  language,
}) {
  const width = 600;
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(() => {
    if (row.name) {
      return row.email;
    } else {
      return "";
    }
  });
  const [name, setName] = useState(() => {
    if (row.name) {
      return row.name;
    } else {
      return "";
    }
  });

  const [active, setActive] = useState(() => {
    if (row.name) {
      return row.active;
    } else {
      return true;
    }
  });
  const [lead, setLead] = useState(() => {
    if (row.lead && row.lead.length > 0) {
      return true;
    } else {
      return false;
    }
  });
  const [edit, setEdit] = useState(() => {
    if (row.edit && row.edit.indexOf(language.toLowerCase()) !== -1) {
      return true;
    } else {
      return false;
    }
  });
  const [view, setView] = useState(() => {
    if (row.view && row.view.indexOf(language.toLowerCase()) !== -1) {
      return true;
    } else {
      return false;
    }
  });
  const [editArray, setEditArray] = useState(() => {
    if (row.edit) {
      
      return [...row.edit];
    } else {
      return [];
    }
  });
  const [viewArray, setViewArray] = useState(() => {
    if (row.view) {
      return [...row.view];
    } else {
      return [];
    }
  });

  useEffect(() => {}, []);

  const classes = useStyles();
  const theme = useTheme();

  const handleAdd = () => {
    if (
      name == "" ||
      name == undefined ||
      email == "" ||
      email == undefined ||
      password == "" ||
      password == undefined
    ) {
      alert("Please enter all fields.");
    } else {
      
      const data = {
        name,
        email,
        active,
        password,
        lead: [],
         view: viewArray,
        edit: editArray,
      };
      okCallback(data);
    }
  };

  const handleCancel = () => {
    cancelCallback();
  };

  const handleUpdate = () => {
    if (name == "" || name == undefined) {
      alert("Please enter all fields.");
    } else {
      const data = {
        id: row.id,
        view: viewArray,
        edit: editArray,
      };
      updateCallback(data);
    }
  };
  const handleDelete = () => {
    let leadIdArray = lead.map(function (element) {
      return element.id;
    });
    let editIdArray = edit.map(function (element) {
      return element.id;
    });
    let viewIdArray = view.map(function (element) {
      return element.id;
    });
    const data = {
      name,
      email,
      id: row.id,
      active,
      lead,
      view,
      edit,
      lead: leadIdArray,
      view: viewIdArray,
      edit: editIdArray,
    };
    deleteCallback(data);
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#727981",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  };

  return (
    // <div className={classes.root}>
    <Dialog style={style} open={visible} onClose={cancelCallback}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 5,
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: width,
          }}
        >
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="name"
              label="NAME"
              value={name}
              disabled={updateFlag}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="email"
              label="EMAIL"
              value={email}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              disabled={updateFlag}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          {!updateFlag && (
            <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
              <TextField
                style={{ width: "100%" }}
                id="password"
                label="PASSWORD"
                value={password}
                InputProps={{
                  disableUnderline: false,
                  margin: "dense",
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </Box>
          )}

          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={edit}
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (editArray.indexOf(language.toLowerCase()) == -1) {
                        let newArray = editArray;
                        newArray.push(language.toLowerCase());
                        setEditArray(newArray);
                      }
                    } else {
                      if (editArray.indexOf(language.toLowerCase()) !== -1) {
                        let index = editArray.indexOf(language.toLowerCase());
                        let newArray = editArray;
                        newArray.splice(index, 1);
                        setEditArray(newArray);
                      }
                    }
                    setEdit(e.target.checked);
                  }}
                />
              }
              label="EDIT"
            />
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={view}
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (viewArray.indexOf(language.toLowerCase()) == -1) {
                        let newArray = viewArray;
                        newArray.push(language.toLowerCase());
                        setViewArray(newArray);
                      }
                    } else {
                      if (viewArray.indexOf(language.toLowerCase()) !== -1) {
                        let index = viewArray.indexOf(language.toLowerCase());
                        let newArray = viewArray;
                        newArray.splice(index, 1);
                        setViewArray(newArray);
                      }
                    }
                    setView(e.target.checked);
                  }}
                />
              }
              label="VIEW"
            />
          </Box>
        </div>

        {updateFlag ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                // width: 40,
              }}
              onClick={handleUpdate}
            >
              UPDATE
            </Button>
            {/* <Button
              style={{
                backgroundColor: "red",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleDelete}
            >
              DELETE
            </Button> */}
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleAdd}
            >
              ADD
            </Button>
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default AddUserByLangaugeDialog;
