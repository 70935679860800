import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {Container, Row, Col}  from 'react-bootstrap';
import { auth, db, signInWithEmailAndPassword } from "../../firebase";
import "./login.css";
function LoginDashboard() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

    
  return (
    <>
    <Container className="login" style={{msOverflowX:"hidden", overflowY:"hidden", textAlign:"center"}}>
      <Col>
      <Row>
    <h1>Mandla Admin Panel</h1>
    </Row>
   <Row>
      
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailAndPassword(email, password)}
        >
          Login
        </button>

      </div>
    </Row>
    </Col>
    </Container>
    </>
  );
}
export default LoginDashboard;
