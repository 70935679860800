import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db } from "../firebase";
import { UserContext } from "./userDataProvider";
import { UserType } from "./config.js";
export const FirebaseContext = createContext({});
//
const FirebaseContextProvider = ({ children }) => {
  const [languages, setAllLanguages] = useState([]);
  const userContext = useContext(UserContext);
  const getLanguage = async (
    role,
    leadLanguageArray,
    editLanguageArray,
    viewLanguageArray
  ) => {
    const querySnapshot = await db.collection("Languages").get();
    let data = [];
    querySnapshot.forEach((doc) => {
      let edit = true;
      let lead = true;
      let view = true;
      let langName = doc.data().name.toLowerCase();
      switch (role) {
        case UserType.superAdmin:
          data.push({
            id: doc.id,
            ...doc.data(),
            edit,
            lead,
            view,
          });
          break;
        case UserType.lead:
          
          if (leadLanguageArray.indexOf(langName) !== -1) {
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          } else if (editLanguageArray.indexOf(langName) !== -1) {
            lead = false;
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          } else if (viewLanguageArray.indexOf(langName) !== -1) {
            lead = false;
            edit = false;
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          }

          break;
        case UserType.editor:
          lead = false;
          if (editLanguageArray.indexOf(langName) !== -1) {
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          } else if (viewLanguageArray.indexOf(langName) !== -1) {
            edit = false;
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          }
          break;
        case UserType.viewer:
          lead = false;
          edit = false;
          if (viewLanguageArray.indexOf(langName) !== -1) {
            data.push({
              id: doc.id,
              ...doc.data(),
              edit,
              lead,
              view,
            });
          }
          break;
      }
    });
    setAllLanguages(data);
  };

  return (
    <FirebaseContext.Provider
      value={{
        setAllLanguages,
        getLanguage,
        languages,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
export default FirebaseContextProvider;
