import logo from "./logo.svg";
import "./App.css";
import React, { Component, useContext, useEffect } from "react";
import UserProvider from "./providers/userDataProvider";


import Application from "./containers/application";
import FirebaseContextProvider from "./providers/firebaseContextProvider";
function App() {
  
  return (
    
    <FirebaseContextProvider>
    <UserProvider>
      <Application />
      </UserProvider>
    </FirebaseContextProvider>
  );
}

export default App;
