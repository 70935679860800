import React, { useEffect, useState, useRef } from "react";

import { makeStyles } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Stack from '@material-ui/lab/Stack';

import Button from "@material-ui/core/Button";

const elementSytle = {
  width: "90%",
};
const boxWidth = 500;
const boxMarginLeft = 10;
const boxMarginTop = 5;
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  boxStyle: {
    minWidth: boxWidth,
    marginTop: boxMarginTop,
  },
}));

function AddUserDialog({
  okCallback,
  updateCallback,
  deleteCallback,
  cancelCallback,
  updateFlag,
  multiSelectRowData,
  row,
  visible,
}) {
  const width = 600;
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState(() => {
    if (row.name) {
      return row.email;
    } else {
      return "";
    }
  });
  const [name, setName] = useState(() => {
    if (row.name) {
      return row.name;
    } else {
      return "";
    }
  });

  const [active, setActive] = useState(() => {
    if (row.name) {
      return row.active;
    } else {
      return true;
    }
  });
  const [lead, setLead] = useState(() => {
    if (row.lead) {
      let modifiedArr = row.lead.map(function (element) {
        let language = multiSelectRowData.filter(
          (c) => c.id.toUpperCase() === element.toUpperCase()
        );
        return language[0];
      });
      return modifiedArr;
    } else {
      return [];
    }
  });
  const [edit, setEdit] = useState(() => {
    if (row.edit) {
      let modifiedArr = row.edit.map(function (element) {
        let language = multiSelectRowData.filter(
          (c) => c.id.toUpperCase() === element.toUpperCase()
        );
        return language[0];
      });
      return modifiedArr;
    } else {
      return [];
    }
  });
  const [view, setView] = useState(() => {
    if (row.view) {
      let modifiedArr = row.view.map(function (element) {
        let language = multiSelectRowData.filter(
          (c) => c.id.toUpperCase() === element.toUpperCase()
        );
        return language[0];
      });
      return modifiedArr;
    } else {
      return [];
    }
  });

  useEffect(() => {}, []);

  const classes = useStyles();
  const theme = useTheme();

  const handleAdd = () => {
    if (
      name == "" ||
      name == undefined ||
      email == "" ||
      email == undefined ||
      password == "" ||
      password == undefined
    ) {
      alert("Please enter all fields.");
    } else {
      let leadIdArray = lead.map(function (element) {
        return element.id.toLowerCase();
      });
      let editIdArray = edit.map(function (element) {
        return element.id.toLowerCase();
      });
      let viewIdArray = view.map(function (element) {
        return element.id.toLowerCase();
      });
      const data = {
        name,
        email,
        active,
        password,
        lead: leadIdArray,
        view: viewIdArray,
        edit: editIdArray,
      };
      okCallback(data);
    }
  };

  const handleCancel = () => {
    cancelCallback();
  };
  const handleUpdate = () => {
    if (name == "" || name == undefined) {
      alert("Please enter all fields.");
    } else {
      let leadIdArray = lead.map(function (element) {
        return element.id.toLowerCase();
      });
      let editIdArray = edit.map(function (element) {
        return element.id.toLowerCase();
      });
      let viewIdArray = view.map(function (element) {
        return element.id.toLowerCase();
      });
      const data = {
        name,
        email,
        id: row.id,
        active,
        lead: leadIdArray,
        view: viewIdArray,
        edit: editIdArray,
      };
      updateCallback(data);
    }
  };
  const handleDelete = () => {
    let leadIdArray = lead.map(function (element) {
      return element.id;
    });
    let editIdArray = edit.map(function (element) {
      return element.id;
    });
    let viewIdArray = view.map(function (element) {
      return element.id;
    });
    const data = {
      name,
      email,
      id: row.id,
      active,
      lead,
      view,
      edit,
      lead: leadIdArray,
      view: viewIdArray,
      edit: editIdArray,
    };
    deleteCallback(data);
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#727981",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  };

  return (
    // <div className={classes.root}>
    <Dialog style={style} open={visible} onClose={cancelCallback}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 5,
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: width,
          }}
        >
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="name"
              label="NAME"
              value={name}
              disabled={updateFlag}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="email"
              label="EMAIL"
              value={email}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              disabled={updateFlag}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          {!updateFlag && (
            <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
              <TextField
                style={{ width: "100%" }}
                id="password"
                label="PASSWORD"
                value={password}
                InputProps={{
                  disableUnderline: false,
                  margin: "dense",
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </Box>
          )}

          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={(e) => {
                    setActive(e.target.checked);
                  }}
                />
              }
              label="ACTIVE"
            />
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <Autocomplete
              style={{ width: "100%" }}
              multiple
              id="tags-outlined"
              options={multiSelectRowData}
              getOptionLabel={(option) => option.id}
              defaultValue={lead}
              filterSelectedOptions
              onChange={(_event, newValues) => {
                setLead(newValues);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="LANGUAGE LEAD"
                  placeholder="ADD LEAD"
                />
              )}
            />
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <Autocomplete
              style={{ width: "100%" }}
              multiple
              id="tags-outlined"
              options={multiSelectRowData}
              getOptionLabel={(option) => option.id}
              defaultValue={edit}
              onChange={(_event, newValues) => {
                setEdit(newValues);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="LANGUAGE EDIT"
                  placeholder="ADD EDIT"
                />
              )}
            />
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <Autocomplete
              style={{ width: "100%" }}
              multiple
              id="tags-outlined"
              options={multiSelectRowData}
              getOptionLabel={(option) => option.id}
              defaultValue={view}
              onChange={(_event, newValues) => {
                setView(newValues);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="LANGUAGE VIEW"
                  placeholder="ADD VIEW"
                />
              )}
            />
          </Box>
        </div>

        {updateFlag ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                // width: 40,
              }}
              onClick={handleUpdate}
            >
              UPDATE
            </Button>
            <Button
              style={{
                backgroundColor: "red",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleDelete}
            >
              DELETE
            </Button>
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleAdd}
            >
              ADD
            </Button>
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default AddUserDialog;
