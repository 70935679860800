import React, {
  Component,
  useContext,
  useEffect,
  useState,
  createContext,
} from "react";
import { auth, getUserDocument } from "../firebase";
import { Link, withRouter, useHistory } from "react-router-dom";
import { FirebaseContext } from "./firebaseContextProvider";

export const UserContext = createContext({ user: null });

function UserProvider({ children }) {
  const [user, setUser] = React.useState(null);
  const history = useHistory();
  const firebaseContext = useContext(FirebaseContext);

  useEffect(async () => {
    auth.onAuthStateChanged(async (userAuth) => {
      //console.log(auth);
      const user = await getUserDocument(userAuth);
      const loggedUser = localStorage.getItem("user");
      //console.log(user);
      // removes the user from local storage on logOut
      if (user) {
        //console.log("hellooooooo");
        // store the user on local storage

        setUser(user);
        localStorage.setItem("user", user);
        // history.push("/");

        // const call = await accountsContext.setLevelAccountsArray(user);
        // const call2 = await accountsContext.setAllSitesArray(user);
        // const call3 =await accountsContext.setCurrentVoucherIndex(user)
        // accountsContext.levelUpdateListener(user);
        // history.replace("/company-dashboard");
      } else {
        auth.signOut();
        localStorage.setItem("user", null);
        // alert("You are not authorized.");
      }
    });
  }, []);
  const logout = async () => {
    auth.signOut();
    setUser(null)
    firebaseContext.setAllLanguages([])
    localStorage.setItem("user", null);
    // history.replace("/");
  };

  return (
    <UserContext.Provider value={{ user, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
