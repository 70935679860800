import React, { useEffect, useState, useRef } from "react";

import { makeStyles } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";

import Button from "@material-ui/core/Button";

const elementSytle = {
  width: "90%",
};
const boxWidth = 500;
const boxMarginLeft = 10;
const boxMarginTop = 5;
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
  boxStyle: {
    minWidth: boxWidth,
    marginTop: boxMarginTop,
  },
}));

function AddLanguageDialog({
  okCallback,
  updateCallback,
  cancelCallback,
  updateFlag,
  row,
  visible,
}) {
  const width = 600;
  const [about, setAbout] = useState(() => {
    if (row.name) {
      return row.about;
    } else {
      return "";
    }
  });
  const [name, setName] = useState(() => {
    if (row.name) {
      return row.name;
    } else {
      return "";
    }
  });
  const [region, setRegion] = useState(() => {
    if (row.name) {
      return row.region;
    } else {
      return "";
    }
  });
  const [availWeb, setAvailWeb] = useState(() => {
    if (row.name) {
      return row.availWeb;
    } else {
      return false;
    }
  });
  const [availMobile, setAvailMobile] = useState(() => {
    if (row.name) {
      return row.availMobile;
    } else {
      return false;
    }
  });
  const [availTrans, setAvailTrans] = useState(() => {
    if (row.name) {
      return row.availTrans;
    } else {
      return false;
    }
  });

  useEffect(() => {}, []);

  const classes = useStyles();
  const theme = useTheme();

  const handleAdd = () => {
    if (
      region == "" ||
      name == "" ||
      about == "" ||
      name == undefined ||
      about == undefined ||
      region == undefined
    ) {
      alert("Please enter all fields.");
    } else {
      const data = {
        name,
        about,
        availWeb,
        availMobile,
        availTrans,
        id: name,
        region: region,
      };
      okCallback(data);
    }
  };

  const handleCancel = () => {
    cancelCallback();
  };
  const handleUpdate = () => {
    if (
      region == "" ||
      name == "" ||
      about == "" ||
      name == undefined ||
      about == undefined ||
      region == undefined
    ) {
      alert("Please enter all fields.");
    } else {
      const data = {
        name,
        about,
        id: name,
        availWeb,
        availMobile,
        availTrans,
        region: region,
      };
      updateCallback(data);
    }
  };
 

  const style = {
    display: "flex",
    flexDirection: "column",
    bgcolor: "#727981",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  };

  return (
    // <div className={classes.root}>
    <Dialog style={style} open={visible} onClose={cancelCallback}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: 5,
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: width,
          }}
        >
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="name"
              label="NAME"
              value={name}
              disabled={updateFlag}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="about"
              label="ABOUT"
              value={about}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              onChange={(e) => {
                setAbout(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <TextField
              style={{ width: "100%" }}
              id="region"
              label="REGION"
              value={region}
              InputProps={{
                disableUnderline: false,
                margin: "dense",
              }}
              onChange={(e) => {
                setRegion(e.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={availWeb}
                  onChange={(e) => {
                    setAvailWeb(e.target.checked);
                  }}
                />
              }
              label="AVAILABLE WEB "
            />
          </Box>
          <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={availMobile}
                  onChange={(e) => {
                    setAvailMobile(e.target.checked);
                  }}
                />
              }
              label="AVAILABLE MOBILE "
            />
          </Box>
           <Box sx={{ minWidth: boxWidth, marginTop: boxMarginTop }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={availTrans}
                  onChange={(e) => {
                    setAvailTrans(e.target.checked);
                  }}
                />
              }
              label="AVAILABLE TRANSLATION "
            />
          </Box>
        </div>

        {updateFlag ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                // width: 40,
              }}
              onClick={handleUpdate}
            >
              UPDATE
            </Button>

            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleAdd}
            >
              ADD
            </Button>
            <Button
              style={{
                backgroundColor: "#379683",
                color: "white",
                marginTop: 5,
                marginBottom: 5,
                width: 40,
              }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default AddLanguageDialog;
