import { db, storage, auth } from "../firebase";
import { CloudURL } from "./config.js";
//////////////////////////////////////// LANGUAGES /////////////////////////////////////
let tmpusers = [
  {
    email: 'nelliottcadge@gmail.com',
    userId: 'Tn6kKMtNNsRWoglKdQP6ASfHCxc2'
  },
  {
    email: 'p3trichr.dreams@gmail.com',
    userId: 'lGNyYLzXITb2O8siEYA3J3PTMtW2'
  },
  {
    email: 'kayelisse15@gmail.com',
    userId: 'VDDNFqK0ZSWNuncReQpidFsdyMr1'
  },
  {
    email: 'rebeccasonusi@gmail.com',
    userId: 'GRXa5cgwtLQVXiEtBKc9Jmhc6Qz1'
  },
  {
    email: '8trwzbjjk6@privaterelay.appleid.com',
    userId: 'V4JbsqtjREfUf4YQEcYCTlDxpRf2'
  },
  {
    email: 'jackeyesunnybunny@gmail.com',
    userId: 'ObFrijrvPGby4QrurtAaeHNf7UD2'
  },
  {
    email: 'yutakaone01@gmail.com',
    userId: 'K6FEr8gl5rQCj6QZZL5lfmuxtjB2'
  },
  {
    email: '29hnp5snsg@privaterelay.appleid.com',
    userId: 'gYEG0sPhfQXrILRrHWC6dJEKE3z2'
  },
  {
    email: 'prosper02.pa@gmail.com',
    userId: 'n5LM13KRR8N0wAXRxjpRh0Sv6Wt1'
  },
  {
    email: 'hermankabungu@gmail.com',
    userId: '6ZU7RPxcy5emIlzHCej07ZfZuTf1'
  },
  {
    email: 'ws000357@gmail.com',
    userId: 'H7AT5ydcSifkOegYHYCMtA9kGnT2'
  },
  {
    email: 'kgai@protonmail.com',
    userId: 't5sTpCoF1BgnBtWFhRJBPgw1rIx2'
  },
  {
    email: 'heavenaharps@gmail.com',
    userId: 'q1h9YM4o3YN1IUKE0IUODoYArf33'
  },
  {
    email: 'liyahlubbs2212@gmail.com',
    userId: 'EHzuhJZrPje3okdjXmdGeE21DKi2'
  },
  {
    email: 'azeezatoluwa@gmail.com',
    userId: 'YT4RcKTwRpOKi5K1IRHsfEGUX9O2'
  },
  {
    email: 'demarcoworldwide@gmail.com',
    userId: 'zzSHs3Trg8MFe6ydz21coiVpymh1'
  },
  {
    email: 'cindystar1078@gmail.com',
    userId: '1L0mAgXACrdh1nOBFANPw9htcwv2'
  },
  {
    email: '65gbhr5rjj@privaterelay.appleid.com',
    userId: 'Gz300ddRKYctp44dTnoLE2ytCSG2'
  },
  {
    email: 'snouanounou10912@gmail.com',
    userId: 'qITE6xXwzyOd3udkRKUpOGBkAlO2'
  },
  {
    email: 'kmupudzi114@gmail.com',
    userId: 'zrTvjdK4wbMkInJR2tv4fjirGHF3'
  },
  {
    email: 'mk2009579@gmail.com',
    userId: 'x4uXyhQJ22a1XNeBxrWC8MzUeEl2'
  },
  {
    email: 'cw74d5vvrr@privaterelay.appleid.com',
    userId: 'hsmY9KhFwdP34DqoE7o08Z28e0C3'
  },
  {
    email: 'seyisunmola03@gmail.com',
    userId: 'nYAhWaZGpvSCezfP1rlveTJUBzE2'
  },
  {
    email: 'wmyers456@gmail.com',
    userId: 'e99yXzB0e1O6ZVtyFdhq5Q3Bm8x1'
  },
  {
    email: 'victoriakishoiyian@gmail.com',
    userId: '5gWptDZLz0d3UFfU6g5UvquyBj43'
  },
  {
    email: 'rachaelokuwa@gmail.com',
    userId: '0dJJJOppVmZjwmyUqH4RSHa03Aw1'
  },
  {
    email: 'skymontrosem@gmail.com',
    userId: 'nM4sxO2sxHTIvZcG8tiEOHKYXUy2'
  },
  {
    email: 'tonyeojuka@gmail.com',
    userId: '7PenFFffqFekckzlPe2GVBDeB4n2'
  },
  {
    email: 'emmanuelihim7@gmail.com',
    userId: 'KuHsZgiqQFSi5q0q4OMpkdxhKlH3'
  },
  {
    email: 'jack-pidge@sky.com',
    userId: 'PBhpFqVaDCMRC74Hs7Pc0yIxMqm1'
  },
  {
    email: 'spsnyeomk@gmail.com',
    userId: 'JN2oj2C6VXb6xJYu9xpSNZI4vJZ2'
  },
  {
    email: 'mananavuyo0@gmail.com',
    userId: 'buFuzNpB7GRdSmOY3TDyIWV0iFP2'
  },
  {
    email: 'sayesiyenga@gmail.com',
    userId: 'w6mxwpncbZbGhQP1oT11K8n6V0I2'
  },
  {
    email: 'j.cm27@icloud.com',
    userId: 'tQJcG9oVvhgMxh7fJHleFZbIyCq2'
  },
  {
    email: 'samyra.odg24@gmail.com',
    userId: 'k98fr582W9eizPIHkv9Ff3hZu0j1'
  },
  {
    email: '2chhgzzr7f@privaterelay.appleid.com',
    userId: 'hali4p5fnaTcFcmLMzCZFJgIyW13'
  },
  {
    email: 'waynerayner.d@gmail.com',
    userId: 'ySsPkRVajjUFWMW17PhvnrJvu0A2'
  },
  {
    email: 'tc5g24dcrq@privaterelay.appleid.com',
    userId: 'bNkzLTEcTnQBPEPy1Hz2AZeN8Rr2'
  },
  {
    email: 'rosiewoodjones@gmail.com',
    userId: 'DarKY2SEoyTBzgkLn8OClUKoze02'
  },
  {
    email: 'wryc2cscq9@privaterelay.appleid.com',
    userId: 'zUKzEk1i7AXPjoWhrUwJUpHnXok2'
  },
  {
    email: 'thebutteredpancake@gmail.com',
    userId: 'KTSUZKp1Efesy30P1UrYq9zJwQv1'
  },
  {
    email: 'adickman13@gmail.com',
    userId: 'I2blnssYPRSlL2mt0ilb3ZRHyZ02'
  },
  {
    email: 'zazmagz@gmail.com',
    userId: 'dEJnkrAhvqXe7bIYvDA6I5WY8GV2'
  },
  {
    email: 'odiboy360@gmail.com',
    userId: 'dJIR87c5NKe5d0LGw7gdk1dQNXh1'
  },
  {
    email: 'hibutterfly235@gmail.com',
    userId: 'zn6KbOzgS5Wune28ZlhS0BlafV92'
  },
  {
    email: 'netleighsello@gmail.com',
    userId: 'r9DSJijYobc0lDfxVs8jbW2GFhZ2'
  },
  {
    email: 'divine.onyebueke19@gmail.com',
    userId: 'dtYzsTqCKeXwjDenQG7PJOXYXfo2'
  },
  {
    email: 'mjwood@cedarville.edu',
    userId: 'fAXkZhtvjbdILCjYkOSaWXC992N2'
  },
  {
    email: 'danelsteyn@gmail.com',
    userId: 'WpzPPWvG8NbEFPImmltBbfyYR0L2'
  },
  {
    email: 'coolklippie@gmail.com',
    userId: 'Vf0iZ73jS9by4eRr3TdfN1BVeCr1'
  },
  {
    email: 'shanban0328@gmail.com',
    userId: 'WMna69GvacN8dPRogulZ0qqTx5l2'
  },
  {
    email: 'tams.show@gmail.com',
    userId: '7So9wgurFQPtjfgqlhZGSwA1Q3T2'
  },
  {
    email: 'schoolbusuwu96@gmail.com',
    userId: 'vMIF3eMPhieXx1cIoL70xK1F1QW2'
  },
  {
    email: 's2gpyb5svh@privaterelay.appleid.com',
    userId: 'l5rqZa3s6HgcMcBQ9aXYFnwofN22'
  },
  {
    email: 'amen4510@isd197.org',
    userId: 'lK5mKZ492qWX4ZaM11WHgMmNaxC2'
  },
  {
    email: 'lookatmeyeahwhatsthebigdeal@gmail.com',
    userId: 'hcQSijVKcVUS5rSOwsQMlyrAMTs1'
  },
  {
    email: 'wffsmpy8zk@privaterelay.appleid.com',
    userId: 'N4SfDfem2LeUaWGAJRAEjaceaGI3'
  },
  {
    email: 't3mu103@gmail.com',
    userId: 'EfruzVmaflVU95n226eD1ksDfeI3'
  },
  { email: 't3mi@icloud.com', userId: 'pcliVCLWHlXKE8ufx73J8dSddba2' },
  {
    email: 'chukwurah55@gmail.com',
    userId: 'osgXDZbQB0YJuPimFgp1durm1Ac2'
  },
  {
    email: 'wtnv4npqkf@privaterelay.appleid.com',
    userId: '02XBPtrEuVMRBuTVZ9sD2MTrmd03'
  },
  {
    email: 'dc2g6s9tsg@privaterelay.appleid.com',
    userId: 'D2kRnKx1glgGptCTKPdji7TiSns2'
  },
  {
    email: 'fatherjermaine4@yahoo.com',
    userId: 'oFBurscizHairRXQfLZ110kIgdX2'
  },
  {
    email: 'zekilotter@icloud.com',
    userId: '4WChtZLvVaYz2hoYeLHjKVotcSy2'
  },
  {
    email: 'orbolarin@gmail.com',
    userId: 'xNvMqMjVmXPbIXkwwkqyoSapYtm2'
  },
  {
    email: 'osborne.mk@gmail.com',
    userId: 'JJjSfOcgK3gvlMsa1m3DfHM8rPz1'
  },
  {
    email: 'jfiyinfoluwa@gmail.com',
    userId: 'JUB25vqmczLMrW1W2np22OYL8Nh2'
  },
  {
    email: 'obiachebe03@icloud.com',
    userId: 'xinh21MCJlgswCc1MctKd742Dht2'
  },
  {
    email: 's_alzowaid@hotmail.com',
    userId: 'E19Y0dss1uam8HL1hmzN1243i9l2'
  },
  {
    email: 'willllllj@gmail.com',
    userId: 'lpwtGuNYrhdPjmUxnh2D3cLDhfG3'
  },
  {
    email: 'evansmari57@gmail.com',
    userId: 't7ESJ7bDW9g4icJROmSIcut8b9O2'
  },
  {
    email: 'zulehaoshodi@icloud.com',
    userId: 'oLzf7XzgLWhfpxVD9b2a6mNuAyz1'
  },
  {
    email: 'blendawit2@gmail.com',
    userId: 'F2BabH9Q0yNpvGbOFQwtyWf52Li1'
  },
  {
    email: 'murisizimbwa@gmail.com',
    userId: '42RywRMOT1hQhKHijTPgXbQymIl2'
  },
  {
    email: 'janee.evans3@gmail.com',
    userId: 'VoKdDcV5shOw1oVAuhUKdytr2162'
  },
  {
    email: 'justjayy23@gmail.com',
    userId: 'hXZqKJu0qVhgB1eFa9JXpfa10pf2'
  },
  {
    email: 'jogomsboikhutso@gmail.com',
    userId: 'fVS53KVrsTbrgAUkWpPOhxeJ4Jk2'
  },
  {
    email: 'mika.kanu@vasterholm.se',
    userId: 'DpS4JlWVU5fkyo0oFtsySsntOpQ2'
  },
  {
    email: 'waychvb@gmail.com',
    userId: 'v2gbsduITsRc2YYM1ZvX6g3eZkD2'
  },
  {
    email: 'drledlt@yahoo.com',
    userId: 'GHrzuSEctRQczrkVRuddHb2fxGS2'
  },
  {
    email: 'olado2015@gmail.com',
    userId: 'ysKMCYKdkQfVE1RGumqhUVY7z073'
  },
  {
    email: 'deborahfasasi1@gmail.com',
    userId: 'oQAdu3vfWlgz1QXtKcqGqNUGL9y2'
  },
  {
    email: 'churchsheep13@gmail.com',
    userId: 'VGAtjS7SX1Or6T06TF4fyMkqQa42'
  },
  {
    email: 'joeyguillory3@gmail.com',
    userId: 'nTr1hFjzFKgMyy44m1jRrOLIgIm2'
  },
  {
    email: 'lucille.antwi2015@gmail.com',
    userId: '1msab7KYwrhxqT2D1nIqyO0WB0G2'
  },
  {
    email: 'malikailboudo1998@gmail.com',
    userId: 'uUhMY9bDbFOmE6SeDlQpfgQPnPj1'
  },
  {
    email: 'aminatasissokotouray@gmail.com',
    userId: 'a7WdMCNI7INug0nOrjlVVpKv5F52'
  },
  {
    email: 'obeneyjoana@gmail.com',
    userId: 'hXUP3L768vYK0SXxBju7mbIfFiC2'
  },
  {
    email: 'sibandathando006@gmail.com',
    userId: 'TXp07oqi4WWZJmifh6bzMkMRUcq2'
  },
  {
    email: 'francoisdutoit2002@icloud.com',
    userId: 'rnW27mshv6c5LmnKvYeRayWjdV23'
  },
  {
    email: 'taudrea99@gmail.com',
    userId: 'rv7ZNI4svwMmdJB9i0e3529dFGA3'
  },
  {
    email: 'kanomagopa@gmail.com',
    userId: 'RHEaCVBdkOhqAZUmdO73hsvJgjZ2'
  },
  {
    email: 'fentibee@gmail.com',
    userId: 'JynNeLmMs3Xg5IaH80GKnUyvGvH2'
  },
  {
    email: '8qyjshjtbj@privaterelay.appleid.com',
    userId: 'OlH4PEyjuCdprYZVe9FerhtJsfp2'
  },
  {
    email: 'sheacreations@gmail.com',
    userId: '1d7erDiYGBfjs9AZuu8RD8dDFrn1'
  },
  {
    email: 'amiahgillfw@gmail.com',
    userId: 'qutuW2XQrkU5W9LlhuNlfQQlils1'
  },
  {
    email: 'adwoasokwakye@gmail.com',
    userId: 'yTqb9rGbEBhfxYQMqMsF8uHkjbL2'
  },
  {
    email: 'kaiwicke07@gmail.com',
    userId: 'DQd2NmLjx0e3EfjafcXhVlOKs6m1'
  },
  {
    email: 'lililens821@gmail.com',
    userId: 'qgbAjwYXzHhiI5Nt1LYiBMODFKx2'
  },
  {
    email: '9jd5n6thdy@privaterelay.appleid.com',
    userId: '2PTdpJdxAjYwErFRZiKtF7max812'
  },
  
    {
      email: 'nelliottcadge@gmail.com',
      userId: 'Tn6kKMtNNsRWoglKdQP6ASfHCxc2'
    },
    {
      email: 'p3trichr.dreams@gmail.com',
      userId: 'lGNyYLzXITb2O8siEYA3J3PTMtW2'
    },
    {
      email: 'kayelisse15@gmail.com',
      userId: 'VDDNFqK0ZSWNuncReQpidFsdyMr1'
    },
    {
      email: 'rebeccasonusi@gmail.com',
      userId: 'GRXa5cgwtLQVXiEtBKc9Jmhc6Qz1'
    },
    {
      email: '8trwzbjjk6@privaterelay.appleid.com',
      userId: 'V4JbsqtjREfUf4YQEcYCTlDxpRf2'
    },
    {
      email: 'jackeyesunnybunny@gmail.com',
      userId: 'ObFrijrvPGby4QrurtAaeHNf7UD2'
    },
    {
      email: 'yutakaone01@gmail.com',
      userId: 'K6FEr8gl5rQCj6QZZL5lfmuxtjB2'
    },
    {
      email: '29hnp5snsg@privaterelay.appleid.com',
      userId: 'gYEG0sPhfQXrILRrHWC6dJEKE3z2'
    },
    {
      email: 'prosper02.pa@gmail.com',
      userId: 'n5LM13KRR8N0wAXRxjpRh0Sv6Wt1'
    },
    {
      email: 'hermankabungu@gmail.com',
      userId: '6ZU7RPxcy5emIlzHCej07ZfZuTf1'
    },
    {
      email: 'ws000357@gmail.com',
      userId: 'H7AT5ydcSifkOegYHYCMtA9kGnT2'
    },
    {
      email: 'kgai@protonmail.com',
      userId: 't5sTpCoF1BgnBtWFhRJBPgw1rIx2'
    },
    {
      email: 'heavenaharps@gmail.com',
      userId: 'q1h9YM4o3YN1IUKE0IUODoYArf33'
    },
    {
      email: 'liyahlubbs2212@gmail.com',
      userId: 'EHzuhJZrPje3okdjXmdGeE21DKi2'
    },
    {
      email: 'azeezatoluwa@gmail.com',
      userId: 'YT4RcKTwRpOKi5K1IRHsfEGUX9O2'
    },
    {
      email: 'demarcoworldwide@gmail.com',
      userId: 'zzSHs3Trg8MFe6ydz21coiVpymh1'
    },
    {
      email: 'cindystar1078@gmail.com',
      userId: '1L0mAgXACrdh1nOBFANPw9htcwv2'
    },
    {
      email: '65gbhr5rjj@privaterelay.appleid.com',
      userId: 'Gz300ddRKYctp44dTnoLE2ytCSG2'
    },
    {
      email: 'snouanounou10912@gmail.com',
      userId: 'qITE6xXwzyOd3udkRKUpOGBkAlO2'
    },
    {
      email: 'kmupudzi114@gmail.com',
      userId: 'zrTvjdK4wbMkInJR2tv4fjirGHF3'
    },
    {
      email: 'mk2009579@gmail.com',
      userId: 'x4uXyhQJ22a1XNeBxrWC8MzUeEl2'
    },
    {
      email: 'cw74d5vvrr@privaterelay.appleid.com',
      userId: 'hsmY9KhFwdP34DqoE7o08Z28e0C3'
    },
    {
      email: 'seyisunmola03@gmail.com',
      userId: 'nYAhWaZGpvSCezfP1rlveTJUBzE2'
    },
    {
      email: 'wmyers456@gmail.com',
      userId: 'e99yXzB0e1O6ZVtyFdhq5Q3Bm8x1'
    },
    {
      email: 'victoriakishoiyian@gmail.com',
      userId: '5gWptDZLz0d3UFfU6g5UvquyBj43'
    },
    {
      email: 'rachaelokuwa@gmail.com',
      userId: '0dJJJOppVmZjwmyUqH4RSHa03Aw1'
    },
    {
      email: 'skymontrosem@gmail.com',
      userId: 'nM4sxO2sxHTIvZcG8tiEOHKYXUy2'
    },
    {
      email: 'tonyeojuka@gmail.com',
      userId: '7PenFFffqFekckzlPe2GVBDeB4n2'
    },
    {
      email: 'emmanuelihim7@gmail.com',
      userId: 'KuHsZgiqQFSi5q0q4OMpkdxhKlH3'
    },
    {
      email: 'jack-pidge@sky.com',
      userId: 'PBhpFqVaDCMRC74Hs7Pc0yIxMqm1'
    },
    {
      email: 'spsnyeomk@gmail.com',
      userId: 'JN2oj2C6VXb6xJYu9xpSNZI4vJZ2'
    },
    {
      email: 'mananavuyo0@gmail.com',
      userId: 'buFuzNpB7GRdSmOY3TDyIWV0iFP2'
    },
    {
      email: 'sayesiyenga@gmail.com',
      userId: 'w6mxwpncbZbGhQP1oT11K8n6V0I2'
    },
    {
      email: 'j.cm27@icloud.com',
      userId: 'tQJcG9oVvhgMxh7fJHleFZbIyCq2'
    },
    {
      email: 'samyra.odg24@gmail.com',
      userId: 'k98fr582W9eizPIHkv9Ff3hZu0j1'
    },
    {
      email: '2chhgzzr7f@privaterelay.appleid.com',
      userId: 'hali4p5fnaTcFcmLMzCZFJgIyW13'
    },
    {
      email: 'waynerayner.d@gmail.com',
      userId: 'ySsPkRVajjUFWMW17PhvnrJvu0A2'
    },
    {
      email: 'tc5g24dcrq@privaterelay.appleid.com',
      userId: 'bNkzLTEcTnQBPEPy1Hz2AZeN8Rr2'
    },
    {
      email: 'rosiewoodjones@gmail.com',
      userId: 'DarKY2SEoyTBzgkLn8OClUKoze02'
    },
    {
      email: 'wryc2cscq9@privaterelay.appleid.com',
      userId: 'zUKzEk1i7AXPjoWhrUwJUpHnXok2'
    },
    {
      email: 'thebutteredpancake@gmail.com',
      userId: 'KTSUZKp1Efesy30P1UrYq9zJwQv1'
    },
    {
      email: 'adickman13@gmail.com',
      userId: 'I2blnssYPRSlL2mt0ilb3ZRHyZ02'
    },
    {
      email: 'zazmagz@gmail.com',
      userId: 'dEJnkrAhvqXe7bIYvDA6I5WY8GV2'
    },
    {
      email: 'odiboy360@gmail.com',
      userId: 'dJIR87c5NKe5d0LGw7gdk1dQNXh1'
    },
    {
      email: 'hibutterfly235@gmail.com',
      userId: 'zn6KbOzgS5Wune28ZlhS0BlafV92'
    },
    {
      email: 'netleighsello@gmail.com',
      userId: 'r9DSJijYobc0lDfxVs8jbW2GFhZ2'
    },
    {
      email: 'divine.onyebueke19@gmail.com',
      userId: 'dtYzsTqCKeXwjDenQG7PJOXYXfo2'
    },
    {
      email: 'mjwood@cedarville.edu',
      userId: 'fAXkZhtvjbdILCjYkOSaWXC992N2'
    },
    {
      email: 'danelsteyn@gmail.com',
      userId: 'WpzPPWvG8NbEFPImmltBbfyYR0L2'
    },
    {
      email: 'coolklippie@gmail.com',
      userId: 'Vf0iZ73jS9by4eRr3TdfN1BVeCr1'
    },
    {
      email: 'shanban0328@gmail.com',
      userId: 'WMna69GvacN8dPRogulZ0qqTx5l2'
    },
    {
      email: 'tams.show@gmail.com',
      userId: '7So9wgurFQPtjfgqlhZGSwA1Q3T2'
    },
    {
      email: 'schoolbusuwu96@gmail.com',
      userId: 'vMIF3eMPhieXx1cIoL70xK1F1QW2'
    },
    {
      email: 's2gpyb5svh@privaterelay.appleid.com',
      userId: 'l5rqZa3s6HgcMcBQ9aXYFnwofN22'
    },
    {
      email: 'amen4510@isd197.org',
      userId: 'lK5mKZ492qWX4ZaM11WHgMmNaxC2'
    },
    {
      email: 'lookatmeyeahwhatsthebigdeal@gmail.com',
      userId: 'hcQSijVKcVUS5rSOwsQMlyrAMTs1'
    },
    {
      email: 'wffsmpy8zk@privaterelay.appleid.com',
      userId: 'N4SfDfem2LeUaWGAJRAEjaceaGI3'
    },
    {
      email: 't3mu103@gmail.com',
      userId: 'EfruzVmaflVU95n226eD1ksDfeI3'
    },
    { email: 't3mi@icloud.com', userId: 'pcliVCLWHlXKE8ufx73J8dSddba2' },
    {
      email: 'chukwurah55@gmail.com',
      userId: 'osgXDZbQB0YJuPimFgp1durm1Ac2'
    },
    {
      email: 'wtnv4npqkf@privaterelay.appleid.com',
      userId: '02XBPtrEuVMRBuTVZ9sD2MTrmd03'
    },
    {
      email: 'dc2g6s9tsg@privaterelay.appleid.com',
      userId: 'D2kRnKx1glgGptCTKPdji7TiSns2'
    },
    {
      email: 'fatherjermaine4@yahoo.com',
      userId: 'oFBurscizHairRXQfLZ110kIgdX2'
    },
    {
      email: 'zekilotter@icloud.com',
      userId: '4WChtZLvVaYz2hoYeLHjKVotcSy2'
    },
    {
      email: 'orbolarin@gmail.com',
      userId: 'xNvMqMjVmXPbIXkwwkqyoSapYtm2'
    },
    {
      email: 'osborne.mk@gmail.com',
      userId: 'JJjSfOcgK3gvlMsa1m3DfHM8rPz1'
    },
    {
      email: 'jfiyinfoluwa@gmail.com',
      userId: 'JUB25vqmczLMrW1W2np22OYL8Nh2'
    },
    {
      email: 'obiachebe03@icloud.com',
      userId: 'xinh21MCJlgswCc1MctKd742Dht2'
    },
    {
      email: 's_alzowaid@hotmail.com',
      userId: 'E19Y0dss1uam8HL1hmzN1243i9l2'
    },
    {
      email: 'willllllj@gmail.com',
      userId: 'lpwtGuNYrhdPjmUxnh2D3cLDhfG3'
    },
    {
      email: 'evansmari57@gmail.com',
      userId: 't7ESJ7bDW9g4icJROmSIcut8b9O2'
    },
    {
      email: 'zulehaoshodi@icloud.com',
      userId: 'oLzf7XzgLWhfpxVD9b2a6mNuAyz1'
    },
    {
      email: 'blendawit2@gmail.com',
      userId: 'F2BabH9Q0yNpvGbOFQwtyWf52Li1'
    },
    {
      email: 'murisizimbwa@gmail.com',
      userId: '42RywRMOT1hQhKHijTPgXbQymIl2'
    },
    {
      email: 'janee.evans3@gmail.com',
      userId: 'VoKdDcV5shOw1oVAuhUKdytr2162'
    },
    {
      email: 'justjayy23@gmail.com',
      userId: 'hXZqKJu0qVhgB1eFa9JXpfa10pf2'
    },
    {
      email: 'jogomsboikhutso@gmail.com',
      userId: 'fVS53KVrsTbrgAUkWpPOhxeJ4Jk2'
    },
    {
      email: 'mika.kanu@vasterholm.se',
      userId: 'DpS4JlWVU5fkyo0oFtsySsntOpQ2'
    },
    {
      email: 'waychvb@gmail.com',
      userId: 'v2gbsduITsRc2YYM1ZvX6g3eZkD2'
    },
    {
      email: 'drledlt@yahoo.com',
      userId: 'GHrzuSEctRQczrkVRuddHb2fxGS2'
    },
    {
      email: 'olado2015@gmail.com',
      userId: 'ysKMCYKdkQfVE1RGumqhUVY7z073'
    },
    {
      email: 'deborahfasasi1@gmail.com',
      userId: 'oQAdu3vfWlgz1QXtKcqGqNUGL9y2'
    },
    {
      email: 'churchsheep13@gmail.com',
      userId: 'VGAtjS7SX1Or6T06TF4fyMkqQa42'
    },
    {
      email: 'joeyguillory3@gmail.com',
      userId: 'nTr1hFjzFKgMyy44m1jRrOLIgIm2'
    },
    {
      email: 'lucille.antwi2015@gmail.com',
      userId: '1msab7KYwrhxqT2D1nIqyO0WB0G2'
    },
    {
      email: 'malikailboudo1998@gmail.com',
      userId: 'uUhMY9bDbFOmE6SeDlQpfgQPnPj1'
    },
    {
      email: 'aminatasissokotouray@gmail.com',
      userId: 'a7WdMCNI7INug0nOrjlVVpKv5F52'
    },
    {
      email: 'obeneyjoana@gmail.com',
      userId: 'hXUP3L768vYK0SXxBju7mbIfFiC2'
    },
    {
      email: 'sibandathando006@gmail.com',
      userId: 'TXp07oqi4WWZJmifh6bzMkMRUcq2'
    },
    {
      email: 'francoisdutoit2002@icloud.com',
      userId: 'rnW27mshv6c5LmnKvYeRayWjdV23'
    },
    {
      email: '9jd5n6thdy@privaterelay.appleid.com',
      userId: '2PTdpJdxAjYwErFRZiKtF7max812'
    },
    {
      email: 'blessedj2001@gmail.com',
      userId: 'C3bVBWS7EeQxrswO0X8ivPn2Fs22'
    },
    {
      email: 'maeshafaith@gmail.com',
      userId: '1Lim5Van83MQGizPya4WRvvxz8m2'
    },
    {
      email: 'marjamg99@gmail.com',
      userId: 'LJ8hamHbbcatDI6JPsMTTveaf3w2'
    },
    {
      email: 'eotutua@gmail.com',
      userId: '1vZhX4xF8RQnNLD7GuVCawdGlKx1'
    },
    {
      email: 'appreyfelicia@gmail.com',
      userId: 'OpUN70sNyIbd1AFT389ZV4a4uqC3'
    },
    { email: 'jokesy@yahoo.com', userId: 'Nl15kRuPpLWWK4kwlOO6CX5BWRI3' },
    {
      email: 'anjolapodeborah12@gmail.com',
      userId: 'moqQhJHXlahPzlLlRnHhDs1TXYm2'
    },
    {
      email: 'kbkrynr5sf@privaterelay.appleid.com',
      userId: 'PHRfjOomOrhigzxf3yptiXFZPLp1'
    },
    {
      email: 'hello@antimatterlabs.ca',
      userId: 'S7Qje3Hx3nSODwdUN2dp1KDgPbk2'
    },
    {
      email: 'nanayaaagyare32@gmail.com',
      userId: 'n1HrmuhYxVaEDSbKPAJXReDMZu33'
    },
    {
      email: 'mike@kenyansm.org',
      userId: 'v2ptlfnYcIcaGq62zSCGPkJgvfh1'
    },
    {
      email: 'tracysonko13@gmail.com',
      userId: 'UlwRFiMnhaPbzkeJxUYH71OxaHq1'
    },
    {
      email: 'zorah.ighomuaye@sascholar.org',
      userId: 'W3ZtoEDq0pQ9uyHxNaq5m9bbJI93'
    },
    {
      email: 'krsmalec@gmail.com',
      userId: 'MYtGRauiQ4TjckUw3p6I63HgnR72'
    },
    {
      email: 'myp7ncq8hb@privaterelay.appleid.com',
      userId: 'xTLjk2Ik6QNt8BR6IKxIO1Zc6AE2'
    },
    {
      email: 'faithwinjack3@gmail.com',
      userId: 'o72mJdPcGAhZr2by0tCfnfzNcnE3'
    },
    {
      email: 'chelseammuoh2@gmail.com',
      userId: 'BLBRr5eQfJPMK6Vsn9boG8KiP4G3'
    },
    {
      email: 'mtaylor6@oglethorpe.edu',
      userId: 'ykzgj031VkgmEn8vy1tlFTjR5KT2'
    },
    {
      email: 'jane.schimper@icloud.com',
      userId: 'L5oerIawyoYhLeNaN8HtvOM040G2'
    },
    {
      email: '7tnzrps5k2@privaterelay.appleid.com',
      userId: 'xy3yTIVN5KMCQe1ITDZTArm34iz1'
    },
    {
      email: 'ailaraharmony@gmail.com',
      userId: 'AqbIZfz8jbWbIYkW1JFHpWGnKAI2'
    },
    {
      email: '4qmpszzp7c@privaterelay.appleid.com',
      userId: 'QsKDRP6fMAMmNHd5lpGZLaKtcMy2'
    },
    {
      email: 'hqpngnz4sm@privaterelay.appleid.com',
      userId: 'ELK0TCe6liZCE6ps0kJ2XTOcCaj1'
    },
    {
      email: 'milourish@gmail.com',
      userId: 'WmFDV9mVnQUnFydvcTB9EHngpK02'
    },
    {
      email: 'kwabena222acheampong@gmail.com',
      userId: 'MofcsHDHfuNr8m45ZlPzC5u1ICm1'
    },
    {
      email: 'ogooluwaalade@gmail.com',
      userId: 'WLvwZbVTrOfSLOlemQJbIuEhPVR2'
    },
    {
      email: 'azratrustfundbaby@gmail.com',
      userId: 'qTgmGh4DZZajX5k142SV6DYyrOr2'
    },
    {
      email: 'intothethiqqofit@gmail.com',
      userId: 'b4fZfRXWqeQzO1aZVJsl27h9W0a2'
    },
    {
      email: 'kubzsarah@gmail.com',
      userId: 'hUl8ougGvCb4X4pjJ1SZnc2SHLa2'
    },
    {
      email: 'moeomieri@icloud.com',
      userId: 'COIXMBCvfceAMGwK2Itbxe94FBT2'
    },
    {
      email: 'bethany.wossen@gmail.com',
      userId: 'RYLxRTHGvUfFHvNRW2tzymmyFvV2'
    },
    {
      email: 'udoka.t.uba@gmail.com',
      userId: 'ekAECDGjGGZUJHtfunNBQY6HE133'
    },
    {
      email: 'jh2g7tsgpw@privaterelay.appleid.com',
      userId: 'kXlvGHrGFDN04IuHGJYxtNf2hxD3'
    }
  ]
export async function addLanguage(data) {
  console.log("Here")
  createProfiles();
}
export async function addCourse(data) {
  try {
    ////console.log(data)
    const ref = await db.collection("MandlaCourses").doc(data.id + "");
    let result = await ref.set(data);
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
} 
export async function createProfile(data) {
  try {
    const ref = await db.collection("Profiles").doc(data.id + "");
    let result = await ref.set(data);
    console.log(data)
    return "done";
  } catch (error) {
    console.error("Error creating document: ", error);
    console.log("FUCK")
    return "error";
  }
}
export async function createProfiles(){
  //iterate throuhg tmpusers and create a profile for each user
  //Create a map
  console.log("here NOW")
  let map = new Map();
  for(let i=0; i<tmpusers.length; i++){
    let newProfile = {
      userId: tmpusers[i].userId,
      email: tmpusers[i].email,
      name: tmpusers[i].email,
      username: tmpusers[i].email,
      progres: map,
      startingDate: "07 06 2022",
      location: "United States",
      admin: false,
      appLang: "English",
    }
    const newUser = await createProfile(newProfile);
}

}
export async function updateLanguage(data) {
  try {
    const ref = await db.collection("Languages").doc(data.id + "");
    let result = await ref.set(
      {
        id: data.name,
        name: data.name,
        about: data.about,
        region: data.region,
        availWeb: data.availWeb,
        availMobile: data.availMobile,
        availTrans: data.availTrans,
      },
      { merge: true }
    );
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
export async function updateCourse(data) {
  try {
    const ref = await db.collection("MandlaCourses").doc(data.id + "");
    let result = await ref.set(
      {
        name: data.name,
        description: data.description,
        teaching: data.language,
        availWeb: data.availWeb,
        availMobile: data.availMobile,
      },
      { merge: true }
    );
    return "done";
  } catch (error) {
    console.error("Error updating document: ", error);
    return "error";
  }
}
export async function deleteLanguage(data) {
  try {
    const ref = await db.collection("Languages").doc(data.id + "");
    let result = await ref.delete();
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// MANDLA COURSES ///////////////////////////////

export const getCourses = async (lang) => {
  try {
    const data = await db
      .collection("MandlaCourses")
      .where("teaching", "==", lang)
      .get();
    let courses = [];
    data.forEach((doc) => {
      courses.push({
        id: doc.id,
        image: doc.data().image,
        name: doc.data().name,
        words: doc.data().words,
      });
    });

    return courses;
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// MANDLA COURSE DETAIL /////////////////////////////////////

export const updateCourseWords = async (id, words) => {
  ////console.log(id, words);
  try {
    const ref = await db.collection("MandlaCourses").doc(id + "");
    let result = await ref.set(
      {
        words,
      },
      { merge: true }
    );
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
};

export const getCourseDetail = async (id) => {
  try {
    const ref = await db.collection("MandlaCourses").doc(id + "");
    let result = await ref.get();
    return result.data();
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
};
/////////////////////////////////////////////////////////////////////////////////////////////
export const uploadFileToStorage = async (file, folderRef, fileRef) => {
  // ////console.log(this.state.image);
  try {
    // Create the file metadata

    const uploadTaskSnapshot = await storage
      .ref(folderRef)
      .child(fileRef)
      .put(file);
    const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
    ////console.log(downloadURL);
    return downloadURL;
  } catch (error) {
    ////console.log("ERR ===", error);
  }
};
export const deleteFileFromFirebase = async (url) => {
  try {
    let fileRef = await storage.refFromURL(url);
    let res = await fileRef.delete();
    ////console.log(res);
    return "done";
  } catch (error) {
    return "error";
  }
};

/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// USERS MANAGEMENT ///////////////////////////////

export const getUsers = async () => {
  try {
    const data = await db
      .collection("Admins")
      .where("role", "!=", "super-admin")
      .get();
    let users = [];
    data.forEach((doc) => {
      users.push({
        id: doc.id,
        ...doc.data(),
      });
    });
////console.log(users)
    return users;
  } catch (error) {
    // console.error("Error removing document: ", error);
    return "error";
  }
};
export const getUsersByLanguage = async (language) => {
  ////console.log(language);
  try {
    const data = await db
      .collection("Admins")
      // .where("role", "!=", "super-admin")
      .where("edit", "array-contains", language.toLowerCase())
      .get();
    let users = [];
    data.forEach((doc) => {
      users.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    const dataa = await db
      .collection("Admins")
      // .where("role", "!=", "super-admin")
      .where("view", "array-contains", language.toLowerCase())
      .get();
    dataa.forEach((doc) => {
      users.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    //removing duplicate
    const ids = users.map((o) => o.id);
    const filtered = users.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    //console.log(users);
    return filtered;
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
};
export async function addUser(data) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const result = await fetch(CloudURL + "useraccounts", requestOptions);
    //console.log(result);
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
export async function deleteUser(data) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const result = await fetch(CloudURL + "deleteUserAccounts", requestOptions);
    //console.log(result);
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
export async function updateUser(data) {
  try {
    const ref = await db.collection("Admins").doc(data.id + "");
    let result = await ref.set(
      {
        id: data.id,
        lead: data.lead,
        edit: data.edit,
        view: data.view,
        active: data.active,
        role: data.role,
      },
      { merge: true }
    );
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
// export async function deleteUser(data) {
//   try {
//     const ref = await db.collection("Languages").doc(data.id + "");
//     let result = await ref.delete();
//     return "done";
//   } catch (error) {
//     console.error("Error removing document: ", error);
//     return "error";
//   }
// }

export async function updateUserLanguage(data) {
  try {
    const ref = await db.collection("Admins").doc(data.id + "");
    let result = await ref.set(
      {
        id: data.id,
        
        edit: data.edit,
        view: data.view,
        role: data.role,
      },
      { merge: true }
    );
    return "done";
  } catch (error) {
    console.error("Error removing document: ", error);
    return "error";
  }
}
