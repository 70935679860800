import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

function LanguageRow(props) {
  let url = "/"+props.name+ "/Dashboard/";
  return (
    <li>
    <div style = {{backgroundColor:"white", width:"100vw", height:"100vh"}}>
    <Link to= {url}>
    {props.name}
    </Link>
    </div>
    </li>
  );
}

export default LanguageRow;
